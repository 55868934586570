import React from "react";
import { styled } from "@mui/system";
import { Grid } from "components/Layout";
import { WindowWidth } from "utils";

import promo from "assets/promo.mp4";

const PromoWrapper = styled("div")(() => ({
  textAlign: "center",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  position: "relative",
  width: "auto",
  height: "100vh",
}));

export default function Promo() {
  let breakPoint = WindowWidth().breakPoint;

  return (
    <Grid container spacing={2}>
      <Grid
        item
        xs={12}
        sx={{
          position: "relative",
          height: breakPoint === "sm" ? "90vh" : "100vh",
        }}
      >
        <PromoWrapper>
          <video
            controls=""
            muted
            autoPlay
            loop
            style={{
              objectFit: "cover",
              width: "100vw",
              height: "100vh",
              position: "fixed",
              top: 0,
              left: 0,
              zIndex: -1,
            }}
            poster="/assets/img/header_home.jpg"
          >
            <source src={promo} type="video/mp4;" />
          </video>
        </PromoWrapper>
      </Grid>
    </Grid>
  );
}
